import { Routes, Route, useLocation } from "react-router-dom";
import Conference from "./components/Conference/Conference.jsx";
import Error from "./components/Error404/Error.jsx";
import FirstConferencePage from "./components/Conference/AggreConference/FirstConference.jsx";
import { useEffect, useState } from "react";
import MainPage from "./components/MainPage.jsx";
import Retrite from "./components/Retrite/Retrite.jsx";
import Flirt from "./components/Conference/FlirtConference/FlirtConference.jsx";
import Profile from "./components/UsersProfiles/Profile.jsx";
import PaymentRedirectPage from "./components/PaymentRedirectPage/PaymentRedirectPage.jsx";
import ResetPassPage from "./components/ResetPassPage/ResetPassPage.jsx";
import WebViewTriggerModal from "./components/Modals/WebViewTriggerModal/WebViewTriggerModal.jsx";
import { useDispatch } from "react-redux";
import { refreshUser } from "./redux/auth/operations.js";
import ProhibitionPage from "./components/Conference/ProhibitionConference/ProhibitionPage.jsx";
// import TechnicalWindow from "./components/TechnicalPage/TechnicalWindow.jsx";

export default function App() {
  // функция для проверки используется ли веб вью (инстаграмный встроенный браузер) для открытия сайта
  function isWebView() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Проверка на Instagram WebView
    if (/Instagram/.test(userAgent)) {
      return true;
    }

    // Общая проверка на WebView
    const isIOSWebView =
      !!window.navigator.standalone ||
      (/iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent));
    const isAndroidWebView =
      /wv/.test(userAgent) ||
      /Android.*Version\/[0-9.]+.*Chrome/.test(userAgent);

    return isIOSWebView || isAndroidWebView;
  }

  const [isInWebView, setIsInWebView] = useState(false);
  useEffect(() => {
    const isWebViewBoolean = isWebView();
    setIsInWebView(isWebViewBoolean);
    if (isWebViewBoolean === true) {
      openWebViewModal();
    }
  }, []);
  const [isWebViewModalOpen, setIsWebViewModalOpen] = useState(false);
  const closeWebViewModal = () => {
    setIsWebViewModalOpen(false);
  };
  const openWebViewModal = () => {
    setIsWebViewModalOpen(true);
  }; // Для прокрутки вверх при переходе на разные страницы сайта
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(refreshUser());
  }, [dispatch]);

  return (
    <>
      <ScrollToTop />

      <Routes>
        {/* <Route path="/" element={<TechnicalWindow />} /> */}
        <Route path="/" element={<MainPage />} />
        <Route path="/thxforpayment" element={<PaymentRedirectPage />} />
        <Route path="/conference" element={<Conference />} />
        <Route path="/aggression" element={<FirstConferencePage />} />
        <Route path="/retrite" element={<Retrite />} />
        <Route path="/*" element={<Error />} />
        <Route path="/resetpassword" element={<ResetPassPage />} />
        <Route path="/flirt" element={<Flirt />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/prohibition" element={<ProhibitionPage />} />
      </Routes>
      {isInWebView === true && (
        <WebViewTriggerModal
          isOpen={isWebViewModalOpen}
          closeModal={closeWebViewModal}
        ></WebViewTriggerModal>
      )}
    </>
  );
}
