import { createSlice } from "@reduxjs/toolkit";
import { getUserData } from "./operations";

export const userData = createSlice({
  name: "userdata",
  initialState: {
    userdata: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.fulfilled, (state, action) => {
      console.log("userData: ", action.payload);
      state.userdata = action.payload.userData;
    });
  },
});

export const userDataReducer = userData.reducer;
