import { createSlice } from "@reduxjs/toolkit";
import {
  register,
  logIn,
  logOut,
  refreshUser,
  signInOrLoginWithGoogle,
  getUserSettings,
  updateUserSettings,
} from "./operations";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {
      firstName: null,
      lastName: null,
      email: null,
      telegram: "",
      additionalInfo: "",
    },
    token: null,
    isLoggedIn: false,
    isRefreshing: false,
    authError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.authError = false;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      console.log("def register: ", action.payload);
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.authError = false;
    });
    builder.addCase(register.rejected, (state) => {
      state.authError = true;
      console.log("auth error");
    });
    builder.addCase(logIn.pending, (state) => {
      state.authError = false;
    });
    builder.addCase(logIn.fulfilled, (state, action) => {
      console.log("def login: ", action.payload);
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.authError = false;
    });
    builder.addCase(logIn.rejected, (state) => {
      state.authError = true;
      console.log("auth error");
    });
    builder.addCase(signInOrLoginWithGoogle.fulfilled, (state, action) => {
      console.log("google: ", action.payload);
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoggedIn = true;
    });
    builder.addCase(logOut.fulfilled, (state) => {
      state.user = {
        name: null,
        email: null,
      };
      state.token = null;
      state.isLoggedIn = false;
    });
    builder.addCase(refreshUser.pending, (state) => {
      state.isRefreshing = true;
    });
    builder.addCase(refreshUser.fulfilled, (state, action) => {
      state.token = action.payload.accessToken;
      state.isLoggedIn = true;
      state.isRefreshing = false;
    });
    builder.addCase(refreshUser.rejected, (state) => {
      state.isRefreshing = false;
    });
    builder.addCase(getUserSettings.fulfilled, (state, action) => {
      console.log("user's settings: ", action.payload);
      state.user = action.payload.user;
    });
    builder.addCase(updateUserSettings.fulfilled, (state, action) => {
      console.log("user's settings: ", action.payload);
      state.user = action.payload.user;
    });
  },
});

export const authReducer = authSlice.reducer;
